import React from "react";
import Appbar from "./appBar";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Grid from "@material-ui/core/Grid";
import Link from "next/link";
import Chatbot from "./ChatBot";
import Head from "next/head";
import { citiesMap, footerLinksCities, propertyTypeNameMap, propertyTypeOrder } from "./constants";

const styles = (theme) => ({
  fullPage: {
    width: "100%",
  },
  footer: {
    width: "100%",
    background: "#3f3f3f",
    textAlign: "left",
    padding: "5%",
    position: "absolute",
    zIndex: 100,
    [theme.breakpoints.down(600)]: {
      padding: "6%",
    },
  },
  footer1: {
    width: "100%",
    background: "#757474",
    textAlign: "left",
    padding: "5%",
    position: "relative",
    zIndex: 100,
    [theme.breakpoints.down(600)]: {
      "& .MuiGrid-container": {
        flexWrap: "inherit !important",
        overflowX: "auto",
        whiteSpace: "nowrap",
      },
    },
    paddingBottom: "2rem",
  },
  socialIcons: {
    display: "flex",
    width: "fit-content",
    margin: "auto",
    padding: "20px 5%",
  },
  socialIcon: {
    padding: "0px 20px",
    cursor: "pointer",
    color: "#fff",
  },
  footerContent: {
    display: "flex",
    width: "fit-content",
    margin: "auto",
    marginBottom: "-2rem",
    padding: "20px",
  },
  footerContents: {
    display: "flex",
    width: "fit-content",
    margin: "auto",
    marginBottom: "2rem",
    "& p": {
      textAlign: "center",
    },
  },
  footerHeading: {
    color: "white",
    opacity: 1,
    fontSize: "16px",
  },
  footerHeading1: {
    color: "white",
    opacity: 1,
    textAlign: "center",
    fontSize: "24px",
  },
  footerLinks: {
    margin: "6px 0",
    color: "white",
    opacity: 0.5,
    paddingTop: "0.5rem",
  },
  pageContent: {
    marginTop: "128px",
  },
  citiesContainer: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    flexWrap: "inherit !important",
    textAlign: 'center'
  },
  footerDiv: {
    position: "relative",
  },
});
// Entry point into the app
class Layout extends React.Component {
  state = {
    pathname: typeof window !== "undefined" && window.location.pathname,
    open: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ open: true });
    }, 50);
  }

  getFooterLinks = () => {
    const { classes } = this.props;
    // return (
    //   <section className={classes.footer1}>
    //     {!this.props.currentCity ? (
    //       <>
    //         <p className={classes.footerHeading1}>Search in Top Cities</p>
    //         {Object.keys(propertyTypeNameMap).includes(this.props.searchRoomType) && (
    //           <Grid container spacing={3} className={classes.citiesContainer}>
    //             {Object.keys(citiesMap).map((city) => (
    //               <Grid item sm={3} className={classes.footerDiv}>
    //                 <a
    //                   href={`https://findmyroom.us/${this.props.searchRoomType}/${footerLinksCities[city].name}`}
    //                   style={{ textDecoration: "none" }}
    //                 >
    //                   <p className={classes.footerHeading}>
    //                     {propertyTypeNameMap[this.props.searchRoomType]} in {footerLinksCities[city].name}
    //                   </p>
    //                 </a>
    //                 {
    //                   footerLinksCities[city].localities.map((locality) => (
    //                     <a
    //                       href={`https://findmyroom.us/${this.props.searchRoomType}/${footerLinksCities[city].name}/${locality}`}
    //                       style={{ textDecoration: "none" }}
    //                     >
    //                       <p className={classes.footerLinks}>
    //                         {propertyTypeNameMap[this.props.searchRoomType]} in {locality}
    //                       </p>
    //                     </a>
    //                   ))
    //                 }
    //               </Grid>
    //             ))}
    //           </Grid>
    //         )}

    //         {!Object.keys(propertyTypeNameMap).includes(this.props.searchRoomType) && (
    //           <Grid container spacing={3} className={classes.citiesContainer}>
    //             {Object.keys(citiesMap).map((city) => (
    //               <Grid item sm={3} className={classes.footerDiv}>
    //                 <a
    //                   href={`https://findmyroom.us/properties/${footerLinksCities[city].name}`}
    //                   style={{ textDecoration: "none" }}
    //                 >
    //                   <p className={classes.footerHeading}>
    //                     {footerLinksCities[city].name}
    //                   </p>
    //                 </a>
    //                 {
    //                   footerLinksCities[city].localities.map((locality) => (
    //                     <a
    //                       href={`https://findmyroom.us/properties/${locality}`}
    //                       style={{ textDecoration: "none" }}
    //                     >
    //                       <p className={classes.footerLinks}>
    //                         {locality}
    //                       </p>
    //                     </a>
    //                   ))
    //                 }
    //               </Grid>
    //             ))}
    //           </Grid>
    //         )}
    //       </>
    //     ) : (
    //       <>{
    //         Object.values(citiesMap).includes(this.props.currentCity) ? (
    //           <>
    //           <p className={classes.footerHeading1}>
    //             Search in Top Localities
    //           </p>
    //           <Grid
    //             container
    //             spacing={3}
    //             className={classes.citiesContainer}
    //           >
    //             {propertyTypeOrder.map((propType) => (
    //               <Grid item sm={3} className={classes.footerDiv}>
    //                 <p className={classes.footerHeading}>{propertyTypeNameMap[propType]}</p>
    //                 {footerLinksCities[Object.keys(citiesMap).find(key => citiesMap[key] === this.props.currentCity)].localities.map((locality) => (
    //                   <a
    //                     href={`https://findmyroom.us/${propType}/${this.props.currentCity}/${locality}`}
    //                     style={{ textDecoration: "none" }}
    //                   >
    //                     <p className={classes.footerLinks}>{propertyTypeNameMap[propType]} in {locality}</p>
    //                   </a>
    //                 ))}
    //               </Grid>
    //             ))}
    //           </Grid>
    //         </>
    //         ) : null
    //       }
    //       </>
    //     )}
    //   </section>
    // )
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.fullPage}>
        <Head>
          <link rel="shortcut icon" href="https://findmyroom.us/favicon.ico" />
        </Head>
        <Appbar
          updateSearch={(result) => this.searchResult(result)}
          isHomePage={this.props.isHomePage}
          showTopBar={this.props.showTopBar}
          searchRoomType={this.props.searchRoomType}
        />
        <div className={!this.props.isHomePage && classes.pageContent}>
          {this.props.children}
        </div>
        {this.getFooterLinks()}
        <section className={classes.footer}>
          <Grid container spacing={3} style={{ textAlign: 'center' }}>
            <Grid item xs={10} sm={4} md={4} className={classes.footerDiv}>
              <p className={classes.footerHeading}>TOP CITIES</p>
              {Object.keys(citiesMap).map((city) => (
                <a
                  href={`https://findmyroom.us/properties/${footerLinksCities[city].name}`}
                  style={{ textDecoration: "none" }}
                >
                  <p className={classes.footerLinks}>{footerLinksCities[city].name}</p>
                </a>
              ))}
            </Grid>
            {/* <Grid item xs={10} sm={3} md={3} className={classes.footerDiv}>
              <p className={classes.footerHeading}>COMMUNITIES</p>
              {citiesMap.map((city) => (
                <a
                  href={`https://www.facebook.com/FindMyRoom${footerLinksCities[city].name}/`}
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <p className={classes.footerLinks}>
                    Flat and Flatmates {footerLinksCities[city].name}
                  </p>
                </a>
              ))}
            </Grid> */}
            <Grid item xs={10} sm={4} md={4} className={classes.footerDiv}>
              <p className={classes.footerHeading}>SERVICES</p>
              <a href="https://findmyroom.us/post/rent" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Find a Roommate</p>{" "}
              </a>
              <a href="https://findmyroom.us/post/rent" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Advertise a room</p>{" "}
              </a>
              <a href="https://findmyroom.us/post/requirement" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>Post a room wanted ad</p>
              </a>
              <a href="https://findmyroom.us/post/rent" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>
                  Advertise a full apartment
                </p>
              </a>
              {/* <a href="https://findmyroom.us/flat-and-flatmates" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>
                  Flat and Flatmates Communities
                </p>
              </a> */}
            </Grid>
            <Grid item xs={10} sm={4} md={4} className={classes.footerDiv}>
              <p className={classes.footerHeading}>COMPANY</p>
              <a href="https://findmyroom.us/contact" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Contact Us</p>
              </a>
              {/* <a
                href="https://findmyroomhelp.zendesk.com/hc/en-us"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p className={classes.footerLinks}>FAQ</p>
              </a> */}
              <a href="https://findmyroom.us/terms-of-use" style={{ textDecoration: "none" }}>
                <p className={classes.footerLinks}>Terms of Use</p>
              </a>
              <a href="https://findmyroom.us/privacy-policy" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>Privacy Policy</p>
              </a>
              <a href="https://findmyroom.us/refund-policy" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>Refund Policy</p>
              </a>
              {/* <a href="https://findmyroom.us/sitemap" style={{ textDecoration: "none" }}>
                {" "}
                <p className={classes.footerLinks}>Sitemap</p>
              </a> */}
            </Grid>
          </Grid>
          <section className={classes.footerContent}>
            <p className={classes.footerHeading}>FOLLOW US ON</p>
          </section>
          <section className={classes.socialIcons}>
            <div className={classes.socialIcon}>
              <a
                href="https://www.facebook.com/findmyroompage"
                style={{ color: "white" }}
              >
                {" "}
                <FacebookIcon />{" "}
              </a>
            </div>
            <div className={classes.socialIcon}>
              <a
                href="https://www.instagram.com/findmyroom/"
                style={{ color: "white" }}
              >
                {" "}
                <InstagramIcon />
              </a>
            </div>
            <div className={classes.socialIcon}>
              <a
                href="https://twitter.com/findmyroom1"
                style={{ color: "white" }}
              >
                {" "}
                <TwitterIcon />{" "}
              </a>
            </div>
            <div className={classes.socialIcon}>
              <a
                href="https://www.linkedin.com/company/findmyroom"
                style={{ color: "white" }}
              >
                {" "}
                <LinkedInIcon />{" "}
              </a>
            </div>
          </section>
          <section className={classes.footerContents}>
            <p className={classes.footerLinks}>
              FindMyRoom ⓒ 2025. All rights reserved
            </p>
          </section>
          {/* {this.state.open && <Chatbot />} */}
        </section>
        <style jsx global>{`
          html,
          body {
            padding: 0;
            margin: 0;
            font-family: Poppins !important;
          }

          * {
            box-sizing: border-box;
          }
        `}</style>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Layout);
